<template>
  <div class="custom-select">
    <label
      for=""
      v-if="selectedOption"
    >{{ selectName }}</label>
    <label
      for=""
      v-if="selectedOption !== placeholderLabel"
    >{{ placeholderLabel }}</label>
    <v-select
      :label="title"
      :options="data"
      :placeholder="selectName"
      @click="openSelect"
      v-model="selectedOption"
      :disabled="disabled"
    >
    <!-- multiple -->
    </v-select>
    <div v-if="clearActive"
        @click="clearSelected()">
        clear
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import vClickOutside from 'click-outside-vue3'
import 'vue-select/dist/vue-select.css'

export default defineComponent({
  name: 'CustomSelect',
  components: {},
  data () {
    return {
      selectOpened: false,
      openSvg: false,
      selectedOption: null,
      ItemValue: ''
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    data: Array,
    selectName: [String, Number],
    title: String,
    placeholderLabel: [String, Number],
    disabled: Boolean,
    clearSelectedFilter: Function,
    clearActive: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    openSelect () {
      if (this.selectOpened === true) {
        this.selectOpened = false
        this.openSvg = false
      } else {
        this.selectOpened = true
        this.openSvg = true
      }
    },
    closeSelect () {
      this.selectOpened = false
      this.openSvg = false
    },
    clearSelected () {
      this.selectedOption = ''
      this.$emit('clearSelected')
    }
  },
  watch: {
    selectedOption (newVal) {
      this.$emit('optionSelected', newVal)
    }
  }
})
</script>

<style lang="scss" scoped>
.custom-select {
  position: relative;
  width: 400px;

  label {
    width: 100%;
    display: block;
    text-align: left;
    padding: 0 0 0 2px;
    font-weight: 500;
    font-size: 12px;
    color: #8c8c8c;
    position: absolute;
    bottom: 25px;
  }

  ::v-deep(.vs__dropdown-toggle) {
    border-radius: 8px;
    border: 1px solid var(--Light, #e9edf2);
    background: var(--White, #fff);
    padding: 0;
  }
  ::v-deep(.vs__search) {
    padding: 0;
    margin: 0;
    position: relative;
    top: 1px;
  }
  ::v-deep(.vs__search::placeholder) {
    color: #8c8c8c;
    font-weight: 500;
    font-size: 18px;
  }
  ::v-deep(.vs__clear svg) {
    display: none;
    background-image: url("../../assets/image/close.png")!important;
    position: absolute;
  }
  ::v-deep(.vs__actions) {
    padding-right: 15px;
  }
  ::v-deep(.vs__actions svg) {
    fill: none;
    border: none;
    background-image: url("../../assets/image/select-arrow.png");
    background-repeat: no-repeat;
    width: 11px;
    height: 8px;
    background-size: contain;
  }
  ::v-deep(.vs__selected) {
    padding: 0;
    margin: 0;
    color: #000000;
    font-size: 14px;
    position: relative;
    top: 3px;
    left: 1px;
    text-align: left;
  }
  ::v-deep(.vs__dropdown-option--highlight) {
    background: #eff8eb !important;
    color: #000000 !important;
  }
  ::v-deep(.vs__dropdown-menu) {
    padding: 0 !important;
    width: 100%!important;
  }
  ::v-deep(.vs__selected-options) {
    height: 48px;
    padding-left: 15px;
  }
  ::v-deep(.vs__dropdown-option--selected) {
    background-image: url("../../assets/svg/select-arrow.svg");
    background-repeat: no-repeat;
    background-position: 5px 10px;
  }
  ::v-deep(.vs__search::placeholder) {
      font-size: 14px;
    }
}

@media (max-width: 999px) {
  .custom-select {
    ::v-deep(.vs__selected-options) {
      height: 40px;
    }
    ::v-deep(.vs__search::placeholder) {
      font-size: 14px;
    }
    ::v-deep(.vs__selected) {
      font-size: 14px;
    }
  }
}
</style>
